<template>
  <component
    :is="tag"
    v-bind="attributes"
    class="btn tw-w-auto tw-cursor-pointer tw-rounded-md tw-border-0 tw-bg-transparent tw-p-2 tw-text-center tw-font-bold tw-text-black tw-transition-all tw-duration-150 tw-ease-out focus-visible:tw-outline focus-visible:tw-outline-2"
    :disabled="disabled"
    @keydown.enter="!disabled && $emit('click')"
    @click="!disabled && $emit('click', $event)"
  >
    <slot />
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import type { ComputedOptions, ConcreteComponent, MethodOptions } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

/*
 * Please remember to make your icon buttons accessible. See https://www.sarasoueidan.com/blog/accessible-icon-buttons/
 *  for some ideas on how to do so.
 */
export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    href: {
      type: [String, Object] as PropType<string | RouteLocationRaw>,
      required: false,
      default: '',
    },
  },
  emits: ['click'],
  computed: {
    tag():
      | string
      | ConcreteComponent<{}, any, any, ComputedOptions, MethodOptions> {
      return this.href ? resolveComponent('NuxtLinkLocale') : 'button'
    },
    attributes(): Record<string, unknown> {
      return this.href
        ? {
            to: this.href,
          }
        : {
            type: 'button',
            disabled: this.disabled,
          }
    },
  },
})
</script>

<style lang="scss" scoped>
.btn {
  min-width: 1.6rem;

  &:disabled {
    @apply tw-cursor-not-allowed tw-opacity-50;
  }

  &.white {
    @apply tw-bg-white;
  }

  &:not(:disabled):not(.white):hover {
    @apply tw-text-black;
    background-color: #33333310;
  }
}
</style>
